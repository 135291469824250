import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import ScrollTrigger from 'gsap/ScrollTrigger'
import HomeView from '@/views/HomeView.vue'

import appData from '@/assets/data/appData.json'

const pages = require.context(`@/assets/data/pages`, true, /\S+\.json$/i);
const pagesContents = require.context(`@/assets/data/pages`, true, /\S+\.md$/i);

const routes: Array<RouteRecordRaw> = [
	{
		path: '/about',
		name: 'About',
		component: () => import('@/views/AboutView.vue')
	},
	{
		path: '/not-found',
		name: '404',
		component: () => import('@/views/PageNotFound.vue')
	},
	{
		path: '/',
		alias: '/:pathMatch(.*)*',
		name: 'Home',
		component: HomeView
	}
]

appData.allPages.forEach(page => {
	routes.push({
		path: `/${page}`,
		name: page,
		component: () => import("@/views/SubPageView.vue"),
		props: { 	
			pageData: pages(`./${page}/Data.json`),
			pageContent: pagesContents(`./${page}/Content.md`).default
		}
	});
});


const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	scrollBehavior(to, from, savedPosition) {
		if (to && to.hash) {
			return {
				selector: to.hash,
				behavior: "smooth"
			}
		}

		if (savedPosition) {
			return savedPosition;
		}

		return { top: 0 }
	},
	routes
})

router.afterEach(() => {
	// Don't really like this, others seem to do this.
	// Need to refresh the scroll trigger after switching pages.
	setTimeout(() => {
		ScrollTrigger.refresh()
	}, 10);
})

export default router
